var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建预存电费","width":1080,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok', this.formData) },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"位置","labelCol":this.formLayout.labelCol,"wrapperCol":this.formLayout.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'location',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ]
                },
              ]),expression:"[\n                'location',\n                {\n                  rules: [\n                    { required: true, message: '请选择' },\n                  ]\n                },\n              ]"}],attrs:{"placeholder":"选择"},on:{"change":_vm.handleLocationChange}},_vm._l((this.dictionaries.location),function(item){return _c('a-select-option',{key:Object.values(item)[0],attrs:{"value":Object.keys(item)[0]}},[_vm._v(" "+_vm._s(Object.values(item)[0])+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"所有人","labelCol":this.formLayout.labelCol,"wrapperCol":this.formLayout.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'property_owner',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ]
                },
              ]),expression:"[\n                'property_owner',\n                {\n                  rules: [\n                    { required: true, message: '请选择' },\n                  ]\n                },\n              ]"}],attrs:{"placeholder":"选择"},on:{"change":_vm.handlePropertyOwnerChange}},_vm._l((this.dictionaries.property_owner),function(val,key){return _c('a-select-option',{key:key,attrs:{"value":key}},[_vm._v(" "+_vm._s(val)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"日期","labelCol":this.formLayout.labelCol,"wrapperCol":this.formLayout.wrapperCol}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['date', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}],staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD"},on:{"change":_vm.time},scopedSlots:_vm._u([{key:"dateRender",fn:function(current){return [_c('div',{staticClass:"ant-calendar-date",style:(_vm.getCurrentStyle(current))},[_vm._v(" "+_vm._s(current.date())+" ")])]}}])})],1),_c('a-form-item',{attrs:{"label":"预存电费金额"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['pre_electricity', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]),expression:"['pre_electricity', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"}],staticStyle:{"width":"180px"},attrs:{"min":0,"precision":2,"placeholder":"请输入"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }