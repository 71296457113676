<template>
  <a-modal
    title="新建预存电费"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok', this.formData) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="位置" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
              <a-select
                @change="handleLocationChange"
                v-decorator="[
                  'location',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ]
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option v-for="item in this.dictionaries.location" :value="Object.keys(item)[0]" :key="Object.values(item)[0]"> {{ Object.values(item)[0] }} </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="所有人" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
              <a-select
                @change="handlePropertyOwnerChange"
                v-decorator="[
                  'property_owner',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ]
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option
                  v-for="(val, key) in this.dictionaries.property_owner"
                  :value="key"
                  :key="key">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="日期" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
              <a-range-picker @change="time" format="YYYY-MM-DD" style="width: 100%" v-decorator="['date', {rules: [{required: true, message: '必填项，请填写信息'}]}]">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)">
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
            <a-form-item label="预存电费金额">
              <a-input-number
                :min="0"
                :precision="2"
                v-decorator="['pre_electricity', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'

// 表单字段
const fields = ['id', 'create_time', 'last_update', 'username', 'password', 'nickname',
  'avatar', 'role', 'email', 'cellphone', 'location', 'is_active']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      category: 0,
      location: 0,
      property_owner: 0,
      formData: {}
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    handleLocationChange (value) {
      this.location = Number(value)
      console.log(value)
    },
    handlePropertyOwnerChange (value) {
      this.property_owner = Number(value)
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.formData.start_date = dateStrings[0]
      this.formData.end_date = dateStrings[1]
    }
  }
}
</script>
