import request from '@/utils/request'

const pre_stored_electricity_feesApi = {
  pre_stored_electricity_fees_list: '/pre_electricity_to_integral/',
  pre_stored_electricity_fees_create: '/pre_electricity_to_integral/',
  pre_stored_electricity_fees_update: '/pre_electricity_to_integral/',
  pre_stored_electricity_fees_delete: '/pre_electricity_to_integral/'
}

/**
 * 列表
 */
export function pre_stored_electricity_fees_list (parameter) {
  return request({
    url: pre_stored_electricity_feesApi.pre_stored_electricity_fees_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function pre_stored_electricity_fees_create (parameter) {
  return request({
    url: pre_stored_electricity_feesApi.pre_stored_electricity_fees_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function pre_stored_electricity_fees_update (parameter, pre_stored_electricity_fees_id) {
  return request({
    url: pre_stored_electricity_feesApi.pre_stored_electricity_fees_update + pre_stored_electricity_fees_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function pre_stored_electricity_fees_delete (pre_stored_electricity_fees_id) {
  return request({
    url: pre_stored_electricity_feesApi.pre_stored_electricity_fees_delete + pre_stored_electricity_fees_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
